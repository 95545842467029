import { Grid } from "@mui/material";
import React from "react";

function MainLayout({ children }) {
  return (
    <Grid container width={"100%"} height="100vh" position="relative">
      <Grid
        item
        xs={12}
        minHeight="67%"
        sx={{
          background:
            "linear-gradient(79deg, rgba(181, 211, 233, 0.50) -10.76%, rgba(33, 150, 243, 0.50) 96.8%)",
        }}
      ></Grid>
      <Grid item xs={12} minHeight="33%" sx={{ background: " #EFF9FE" }}></Grid>
      <Grid container item xs={12} position="absolute">
        {children}
      </Grid>
    </Grid>
  );
}

export default MainLayout;
