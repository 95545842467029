import React, { createContext, useState } from "react";


const AudioContext = createContext(undefined);
const AudioDispatchContext = createContext(undefined);


function AudioProvider({ children }) {
  const [audio, setAudio] = useState(false);
  return (
    <AudioContext.Provider value={audio}>
      <AudioDispatchContext.Provider value={setAudio}>
        {children}
      </AudioDispatchContext.Provider>
    </AudioContext.Provider>
  );
}

export { AudioProvider, AudioContext, AudioDispatchContext };
