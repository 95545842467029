import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import NavBar from "./components/NavBar";
import MainLayout from "./layouts/MainLayout";
import Welcome from "./views/welcome";
import { AudioProvider } from "./context/AudioContext";

const breakpoints = createTheme().breakpoints;
const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    [breakpoints.down("sm")]: {
      fontSize: "0.1rem",
    },
    [breakpoints.up("md")]: {
      fontSize: "5rem",
    },
  },
  palette: {
    secondary: { main: "#FF368C" },
    primary: { main: "#005FC5" },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MainLayout>
      <AudioProvider>
        <NavBar />
        <Welcome />
        </AudioProvider>
      </MainLayout>
    </ThemeProvider>
  );
}

export default App;
