import { Dialog, Divider, Grid, Paper } from "@mui/material";
import PropTypes from "prop-types";
import { ReactComponent as XIcon } from "../assets/icons/x.svg";

function DialogLayout({
  children,
  xs = 8,
  md,
  style,
  open,
  onClose,
}) {

  return (
    <Grid item xs={xs} md={md} sx={{ padding: 2, margin: 2, ...style }}>
      <Paper sx={{ paddingX: 8, overflow: "hidden" }}>
      <Dialog
        open={open}
        onClose={onClose}
        justifyContent="center"
        alignItems="center"
        sx={{ border: "none", overflow: "hidden" }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
          sx={{ padding: 2, cursor: "pointer" }}
        >
          <XIcon onClick={onClose} />
        </Grid>
        <Divider width="100%" />
        {children}
      </Dialog>
      </Paper>
    </Grid>
  );
}
DialogLayout.propTypes = {
  md: PropTypes.number, // Width for medium screens
  sm: PropTypes.number, // Width for small screens
};

export default DialogLayout;
