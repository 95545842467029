import React, { useContext } from "react";
import { Button, Grid } from "@mui/material";
import { ReactComponent as PowerOffIcon } from "../assets/icons/PowerOff.svg";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { IconButton } from "@mui/material";
import { AudioContext, AudioDispatchContext } from "../context/AudioContext";

function NavBar() {
  const AudioDetails = React.useContext(AudioContext);
  const setAudioDetails = useContext(AudioDispatchContext);
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="flex-end"
      alignItems="center"
      padding={2}
    >
       {AudioDetails && (
        <IconButton
          onClick={() => {
            setAudioDetails(false);
          }}
        >
          <VolumeUpIcon />
        </IconButton>
      )}
      {!AudioDetails && (
        <IconButton
          onClick={() => {
            setAudioDetails(true);
          }}
        >
          <VolumeOffIcon />
        </IconButton>
      )}
     
      <Button
        variant="contained"
        color="primary"
        sx={{
          textTransform: "none",
          color: "white",
          height: "40px",
          marginRight:'10px'
        }}
        endIcon={<PowerOffIcon />}
      >
        Disconnect
      </Button>
    </Grid>
  );
}

export default NavBar;
