import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

// Relative Dependencies
import { firebaseConfig } from "./firebaseConfig";

export default firebase.initializeApp(firebaseConfig);

export const database = firebase.database();
export const auth = firebase.auth();
