import BoxLayout from "../../../layouts/BoxLayout";
import { Typography } from "@mui/material";
import { welcomePageData } from "../data/data.js";
import { Fragment } from "react";

function MainCard() {
  return (
    <BoxLayout style={{ textAlign: "start" }}>
      {welcomePageData.map((item, index) => (
        <Fragment key={index}>
          <Typography
            key={item.id}
            variant={item.variant}
            sx={{ ...item.style }}
          >
            {item.subTitle ? (
              <span style={{ color: "#FF368C" }}>{item.subTitle}</span>
            ) : (
              ""
            )}
            {item.title}
          </Typography>
          <br />
        </Fragment>
      ))}
    </BoxLayout>
  );
}

export default MainCard;
