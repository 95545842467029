import { Grid, Typography } from "@mui/material";
import { ReactComponent as SmiBanner } from "../../../assets/icons/SmiBanner.svg";
import useDateHandler from "../../../hooks/useDateHandler";

function Header() {
  const { formattedDate } = useDateHandler();
  return (
    <Grid
      container
      item
      xs={6}
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      <Grid item md={3} xs={12}>
        <SmiBanner />
      </Grid>
      <Grid container item md={9} xs={12} flexDirection="column">
        <Typography variant="h3" color="white">
          Bonjour SMI Team
        </Typography>
        <Typography variant="h5" color="black">
          Le {formattedDate}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Header;
