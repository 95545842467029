import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Header from "./components/Header";
import MainCard from "./components/MainCard";
import InfluencerModal from "./components/modals/InfluencerModal";
import SaleModal from "./components/modals/SaleModal";
import notifSound from "../../assets/audio/notifSound.mp3";
import { database } from "../../config/firebase";
import { AudioContext } from "../../context/AudioContext";

const firstObserver = { influencer: true, purchase: true };

function Welcome() {
  const initialState = { influencer: false, purchase: false };
  const [open, setOpen] = useState(initialState);
  const [data, setData] = useState(null);
  const AudioDetails = React.useContext(AudioContext);
  const [dataQueue, setDataQueue] = useState([]);

  const openModal = (data, type) => {
    setOpen({ influencer: type === "influencer", purchase: type === "purchase" });
    if (AudioDetails) {
      const audio = new Audio(notifSound);
      audio.play().catch((error) => console.error(error));
    }
    setData(data);
  };

  const closeModal = () => setOpen(initialState);

  const handleDataRef = (node,type) => {
    const dataRef = database.ref(node).orderByChild("createdAt").limitToLast(1);
    firstObserver[type] = true;
    dataRef.off("child_added");
    dataRef.on("child_added", (snapshot) => {
      if (firstObserver[type]) {
        firstObserver[type] = false;
        return;
      }
      const newData = snapshot.val();
      setDataQueue((prevQueue) => [...prevQueue, { data: newData, type }]);
    });
    return dataRef;
  };

  useEffect(() => {
    const dataRefInfluencer = handleDataRef("influencers","influencer");
    const dataRefPurchase = handleDataRef("conversions/purchase","purchase");

    return () => {
      dataRefInfluencer.off("child_added");
      dataRefPurchase.off("child_added");
    };
  }, [AudioDetails]);

  useEffect(() => {
    const handleQueue = async () => {
      if (dataQueue.length > 0 && !open.influencer && !open.purchase) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        openModal(dataQueue[0].data, dataQueue[0].type);
        setDataQueue((prevQueue) => prevQueue.slice(1));
        setTimeout(() => closeModal(), 10000);
      }
    };

    handleQueue();
  }, [open, dataQueue]);

  return (
    <Fragment>
      <InfluencerModal open={open.influencer} onClose={closeModal} data={data} />
      <SaleModal open={open.purchase} onClose={closeModal} data={data} />
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <Header />
      </Grid>
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <MainCard />
      </Grid>
    </Fragment>
  );
}

export default Welcome;
