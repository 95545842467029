import { Tooltip, Typography } from "@mui/material";

import { getCountryName } from "../utils/utils";

const baseUrlFlag = (variant) =>
  `https://firebasestorage.googleapis.com/v0/b/smi-fnac-dev.appspot.com/o/icons%2Fflags%2F${variant}.svg?alt=media&token=18e48b8f-ee13-45e1-9ce2-a2dee55eff53`;
function Flag({ variant, withName, withTooltipName = false, ml, ...props }) {
  return (
    <Tooltip
      title={getCountryName(variant)}
      disableHoverListener={!withTooltipName}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "max-content",
          maxWidth: "130px",
          height: "19px",
          flexWrap: "nowrap",
          gap: "10px",
          marginLeft: ml ? ml : "0px",
        }}
      >
        <img
          src={baseUrlFlag(variant?.toLowerCase())}
          alt="flag"
          style={{
            width: "24px",
            height: "100%",
            objectFit: "cover",
            borderRadius: "2px",
          }}
        />
        {withName && (
          <Typography
            variant="body1"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "black",
            }}
          >
            {getCountryName(variant)}
          </Typography>
        )}
      </div>
    </Tooltip>
  );
}

export default Flag;
