import { differenceInYears } from "date-fns";
import countriesDB from "./countriesDB";

import { database } from "../config/firebase";

/**
 * @param {*} query
 * @param {value, child_added, child_changed, child_moved, child_remover} actionType
 * @param {fn} callback
 */
export const observer = (query, actionType, callback) => {
  query.on(
    actionType,
    function (snapshot) {
      var data = snapshot.val();
      callback(data);
    },
    function (error) {
      console.error(error);
    }
  );
};

export function getCountryName(countryCode) {
  const country = countriesDB.find(
    (c) => c.code === countryCode?.toUpperCase()
  );

  return country?.nameFr;
}

export function getAgeFromTimestamp(timestampSeconds) {
  let result = "- ans";
  if (timestampSeconds) {
    const timeStampMS =
      timestampSeconds.length > 10 ? timestampSeconds : timestampSeconds * 1000;
    const birthDate = new Date(timeStampMS);

    // Get the current date
    const currentDate = new Date();

    // Calculate the age
    const age = differenceInYears(currentDate, birthDate);
    result = `${age} ans`;
  }
  return result;
}

async function getInfluencerNameByKey(influencerId) {
  return await database
    .ref(`influencers/${influencerId}`)
    .once("value")
    .then((snap) => {
      return snap.exists() ? snap?.val()?.name : "";
    });
}

export async function formatSaleData(data) {
  try {
    const titleStyle = { color: "black" };
    const descStyle = { color: "black", fontWeight: 600 };
    const commissionSmi = data?.commission - data?.commissionAffiliate ?? 0;
    const influencerName = await getInfluencerNameByKey(data.influencer);

    const result = [
      {
        title: "Faite par:",
        desc: influencerName,
        titleStyle: { fontWeight: 600, color: "black" },
        descStyle: { color: "secondary", fontWeight: 600 },
      },
      {
        title: "Marque",
        desc: data?.brandName,
        titleStyle: titleStyle,
        descStyle: descStyle,
      },
      {
        title: "Commission global",
        desc: `${data?.commission} EUR`,
        titleStyle: titleStyle,
        descStyle: descStyle,
      },

      {
        title: "Commission influenceur",
        desc: `${data?.commissionAffiliate} EUR`,
        titleStyle: titleStyle,
        descStyle: descStyle,
      },
      {
        title: "Commission SMI",
        desc: `${commissionSmi} EUR`,
        titleStyle: titleStyle,
        descStyle: descStyle,
      },
    ];
    return result ?? [];
  } catch (err) {
    console.log(err);
    return [];
  }
}
