import { useState, useEffect } from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

function useDateHandler() {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formattedDate = format(currentDate, "EEEE d MMMM y H:mm:ss", {
    locale: fr,
  });

  return { formattedDate };
}

export default useDateHandler;
