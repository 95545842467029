export const welcomePageData = [
  {
    id: 1,
    title: "Bienvenue sur votre tableau de bord interactif ! 🎉",
    variant: "h5",
    style: { fontWeight: 600, paddingY: 4, textAlign: "center" },
  },
  {
    id: 2,
    title:
      "Ici, vous serez instantanément informés des dernières activités et dessuccès de votre équipe.",
    variant: "body2",
    style: { color: "black", whiteSpace: "pre-line" },
  },
  {
    id: 3,
    title: "Rester à l’affut : ",
    variant: "body2",
    style: { color: "black", whiteSpace: "pre-line" },
  },
  {
    id: 4,
    subTitle: "-Ventes réalisées:",
    title: "  Célébrez chaque vente réalisée en temps réel !",
    variant: "body2",
    style: { color: "black", fontWeight: 600 },
  },
  {
    id: 5,
    subTitle: "-Nouveaux Utilisateurs:",
    title: " Souhaitez la bienvenue aux nouveaux membres de votre communauté",
    variant: "body2",
    style: { color: "black", fontWeight: 600 },
  },
  {
    id: 6,
    title: `Restez Motivés et Impliqués ! Votre succès est notre réussite commune.
      Chaque notification est un pas de plus vers l’excellence.`,
    variant: "body2",
    style: { color: "black" },
  },
  {
    id: 7,
    title: `L’écran est actuellement vide ? pas d’inquiétude ! cela signifie
    simplement que vous etes tous à jour. Continuez votre excellent travail,
    et bientot, cet écran s’illuminera de vos succès !`,
    variant: "body2",
    style: { color: "black" },
  },
];
