import React from "react";
import { Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import Flag from "../../../../ui/Flag";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import { getAgeFromTimestamp } from "../../../../utils/utils";
import { useTheme } from "@emotion/react";
import DialogLayout from "../../../../layouts/DialogLayout";

const gendersData = {
  mr: {
    color: "info",
    icon: <MaleIcon />,
  },
  mrs: {
    color: "secondary",
    icon: <FemaleIcon />,
  },
};

function InfluencerModal({ open, onClose, data }) {
  const age = getAgeFromTimestamp(data?.dateOfBirthTimestamp);
  const theme = useTheme();
  return (
    <DialogLayout
      open={open}
      onClose={onClose}
      md={3}
      style={{ overflow: "hidden" }}
    >
      <Grid
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        padding={4}
        gap={4}
      >
        <Typography variant="h5" fontWeight={600}>
          Nouveau influenceur 🎉
        </Typography>
        <Divider width="100%" />
        <Grid container xs={12} justifyContent="space-between" gap={4}>
          <img
            width={210}
            height={105}
            src={data?.banner}
            alt="prodcut"
            style={{ borderRadius: 16 }}
          />
          <Stack spacing={2}>
            <Typography variant="h6" fontWeight={600}>
              {data?.name}
            </Typography>
            <Flag variant={data?.country?.toLowerCase()} withName />
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Typography>
                <span style={{ fontWeight: 600, fontSize: "24px" }}>{age}</span>{" "}
              </Typography>

              <Chip
                icon={gendersData[data?.civility]?.icon}
                color={gendersData[data?.civility]?.color}
                sx={{
                  padding: "10px",
                  justifyContent: "center",
                  transform: "translateX(32px)",
                  overflowX: "clip",
                  borderRadius: "20px 0 0 20px",
                  [theme.breakpoints.down("xs")]: {
                    borderRadius: "20px", // For small screens, make it fully rounded
                    transform: "none", // Reset the transform property
                    overflowX: "hidden", // Reset the overflow property
                  },
                }}
              />
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </DialogLayout>
  );
}

export default InfluencerModal;
