import React, { Fragment, useEffect, useState } from "react";
import { CircularProgress, Divider, Grid, Typography } from "@mui/material";

import Flag from "../../../../ui/Flag";
import { formatSaleData } from "../../../../utils/utils";
import DialogLayout from "../../../../layouts/DialogLayout";

const ProductTitleUI = ({ title, desc, titleStyle, descStyle }) => {
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="space-between"
      alignItems="center"
      paddingY={1}
    >
      <Typography sx={{ ...titleStyle }}>{title}</Typography>
      <Typography sx={{ ...descStyle }}>{desc}</Typography>
    </Grid>
  );
};

function SaleModal({ open, onClose, data }) {
  const [saleData, setSaleData] = useState([]);
  useEffect(() => {
    async function getSaleData() {
      if (data) {
        const result = await formatSaleData(data);
        setSaleData(result);
      }
    }
    getSaleData();
  }, [data]);
  return (
    <DialogLayout open={open} onClose={onClose} md={3}>
      <Grid
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        padding={4}
        gap={4}
      >
        <Typography variant="h3">Nouvelle vente 🎉</Typography>
        {data && saleData.length ? (
          <>
            <img
              width={150}
              height={150}
              src={data?.articleImgUrl}
              alt="prodcut"
              style={{ borderRadius: 16 }}
            />
            <Grid container item xs={12}>
              {saleData?.map((item, index) => (
                <Fragment key={index}>
                  <ProductTitleUI
                    titleStyle={item?.titleStyle}
                    descStyle={item?.descStyle}
                    title={item?.title}
                    desc={item?.desc}
                    key={index}
                  />
                  <Divider style={{ width: "100%" }} />
                </Fragment>
              ))}
            </Grid>
            <Grid container item xs={12}>
              <Flag variant="fr" withName />
            </Grid>
          </>
        ) : (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </DialogLayout>
  );
}
export default SaleModal;
