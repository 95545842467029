import { Dialog, Divider, Grid, Paper } from "@mui/material";
import PropTypes from "prop-types";
import { ReactComponent as XIcon } from "../assets/icons/x.svg";

function BoxLayout({
  children,
  xs = 8,
  md,
  style,
}) {

  return (
    <Grid item xs={xs} md={md} sx={{ padding: 2, margin: 2, ...style }}>
      <Paper sx={{ paddingX: 8, overflow: "hidden" }}>
      <Grid container flexDirection="column">
        {children}
      </Grid>
      </Paper>
    </Grid>
  );
}
BoxLayout.propTypes = {
  md: PropTypes.number, // Width for medium screens
  sm: PropTypes.number, // Width for small screens
};

export default BoxLayout;
