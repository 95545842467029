export const env = process.env.REACT_APP_STAGE;
export const firebaseConfig = {
  apiKey: "AIzaSyDFpc4hKOKbwBx0_wcNjrJIZNxw91ysQ6A",
  authDomain: "shopmyinfluens.firebaseapp.com",
  databaseURL: ["prod", "local"].includes(env)
    ? "https://shopmyinfluens.firebaseio.com"
    : "http://localhost:9000/?ns=shopmyinfluens",
  projectId: "shopmyinfluens",
  storageBucket: "shopmyinfluens.appspot.com",
  messagingSenderId: "1007757627682",
  appId: "1:1007757627682:web:a4a2133a1aaa2207fd557b",
  measurementId: "G-MV4B73S4JV",
};
